import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    word-wrap: break-word;
  }
  html {
    font-size: 62.5%;
    line-height: 1.6;
    /* Footer support */
    position: relative;
    min-height: 100%;
  }
  body {
    font-family: "Roboto", sans-serif;
    font-size: 1.2rem;
    background-color: ${({ theme }) => theme.palette.common.white};
  }
  #root{
    display:flex;
    flex-flow: column nowrap;
    min-height:100vh;
  }
  header {
    font-size: 2.4rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.2;
  }
  a {
    text-decoration: none;
  }
  img {
   max-width: 100%;
   height: auto;
  }
  button,
  input,
  textarea {
    border: none;
    outline: none;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
  }
  button {
    cursor: pointer;
  }
`;

export default GlobalStyle;
