import styled from "styled-components";

const FormButton = styled.button`
  width: 100%;
  font-family: sans-serif;
  font-size: 1.6rem;
  border-radius:5px;
  text-align: center;
  font-family: ${({theme}) => theme.primaryFont};
  padding: 0.7rem;
  cursor:pointer;
  pointer-events:auto;
  font-weight: bold;
  max-width:250px;
  pointer-events:auto;
  margin-top: 1rem;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  :disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export default FormButton;
