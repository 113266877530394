import styled from "styled-components";

const WarningText = styled.p`
  color: #FF160C;
  font-size: 1.25rem;
  font-family: ${({theme}) => theme.primaryFont};
  text-align: center;
  max-width:300px;
`;

export default WarningText;