import { Course, Lesson } from "../../../types"
import React from 'react';
import Section from "./SectionCard/Section";
import styled from "styled-components";
import { Section as ISection } from "../../../types";

interface IProps {
    sections : ISection[],
    changeSelectedChat: (lesson : Lesson) => void;
    activeLesson : Lesson
}

const CourseNavigation = ({sections, changeSelectedChat, activeLesson} : IProps) => {
    
    return (
        <CourseNavigationWrapper>
            {sections.map((section, index) => <Section section = {section} sectionNumber={index + 1} changeSelectedChat = {changeSelectedChat} activeLesson = {activeLesson}/>)}
        </CourseNavigationWrapper>
    )
}
const CourseNavigationWrapper = styled.div`
    display:flex;
    flex-flow:column nowrap;
    padding: 2rem 1rem 2rem 1rem;
    overflow-y:auto;
    height:100vh;
    background-color:${({theme}) => theme.palette.common.white};
    gap:2rem;
    width: 20%;
    
`;

export default CourseNavigation;