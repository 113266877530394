import styled, {css} from "styled-components";

export const BasicText = styled.p<{
    fontWeight: string;
    fontSize: string;
    color?: string;
    wrapText?: boolean;
    fadeText?: boolean;
    sizeQuery?:boolean;
    cursor?: string;
    textAlign?: string;
    fontFamily?:boolean;
  }>`
    font-family: ${({ theme }) => theme.primaryFont};
    color: ${({ theme }) => theme.palette.primary.contrastText};
    font-size: ${({ fontSize }) => fontSize};
    font-weight: ${({ fontWeight }) => fontWeight};
    ${({ color }) =>
      color === "grey" &&
      css`
        color: ${({ theme }) => theme.palette.common.darkGrey};
      `}
    ${({ color }) =>
      color === "blue" &&
      css`
        color: ${({ theme }) => theme.palette.secondary.main};
      `}
      
      ${({ wrapText }) =>
      wrapText === false &&
      css`
        white-space: nowrap;
      `}
      ${({ fadeText }) =>
      fadeText === true &&
      css`
        opacity: 0.5;
      `}
      ${({ cursor }) =>
      cursor &&
      css`
        cursor: pointer;
      `}
      ${({ textAlign }) =>
      textAlign &&
      css`
        text-align: ${textAlign};
      `}
      ${({ fontFamily }) =>
      fontFamily &&
      css`
        font-family: 'Castoro Titling', sans-serif;
      `}
      
      ${({sizeQuery}) => sizeQuery && 
      css` 
      @media only screen and (min-width: 540px) {
        font-size: 1.5rem;
      }
      @media only screen and (min-width: 768px) {
        font-size: 1.7rem;
      }`
    }
  `;
  
  

  export const BasicSpan = styled.span<{
    fontWeight: string;
    fontSize: string;
    color?: string;
    wrapText?: boolean;
    fadeText?: boolean;
    sizeQuery?:boolean;
    cursor?: string;
    textAlign?: string;
  }>`
    font-family: ${({ theme }) => theme.primaryFont};
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: ${({ fontSize }) => fontSize};
    cursor:pointer;
    font-weight: ${({ fontWeight }) => fontWeight};
    ${({ color }) =>
      color === "grey" &&
      css`
        color: ${({ theme }) => theme.palette.common.darkGrey};
      `}
    ${({ color }) =>
      color === "blue" &&
      css`
        color: ${({ theme }) => theme.palette.secondary.main};
      `}
      
      ${({ wrapText }) =>
      wrapText === false &&
      css`
        white-space: nowrap;
      `}
      ${({ fadeText }) =>
      fadeText === true &&
      css`
        opacity: 0.5;
      `}
      ${({ cursor }) =>
      cursor &&
      css`
        cursor: pointer;
      `}
      ${({ textAlign }) =>
      textAlign &&
      css`
        text-align: ${textAlign};
      `}
      
      ${({sizeQuery}) => sizeQuery && 
      css` 
      @media only screen and (min-width: 540px) {
        font-size: 1.5rem;
      }
      @media only screen and (min-width: 768px) {
        font-size: 1.7rem;
      }`
    }
  `;
  
  