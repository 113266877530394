import { Section } from "../../types";
const mockSections: Section[] = [
    {
      section_id: '1',
      title: 'Introduction to HTML',
      order_id: '1',
      course_id: '1',
      lessons: [
        {
          lesson_id: '1',
          title: 'What is HTML?',
          description: 'Introduction to HTML and its uses',
          video_url: 'https://www.youtube.com/watch?v=8JQaedsB2qI',
          order_id: '1',
          section_id: '1',
          course_id: '1',
        },
        {
          lesson_id: '2',
          title: 'Basic HTML Tags',
          description: 'Introduction to some basic HTML tags',
          video_url: 'https://www.youtube.com/watch?v=rOPKC49gTkk',
          order_id: '2',
          section_id: '1',
          course_id: '1',
        },
      ],
    },
    {
      section_id: '2',
      title: 'Introduction to CSS',
      order_id: '2',
      course_id: '1',
      lessons: [
        {
          lesson_id: '3',
          title: 'What is CSS?',
          description: 'Introduction to CSS and its uses',
          video_url: 'https://www.youtube.com/watch?v=CUxH_rWSI1k',
          order_id: '1',
          section_id: '2',
          course_id: '1',
        },
        {
          lesson_id: '4',
          title: 'CSS Selectors',
          description: 'Introduction to CSS selectors',
          video_url: 'https://www.youtube.com/watch?v=g0Aq2kP5-CY',
          order_id: '2',
          section_id: '2',
          course_id: '1',
        },
        {
          lesson_id: '5',
          title: 'CSS Box Model',
          description: 'Introduction to the CSS box model',
          video_url: 'https://www.youtube.com/watch?v=9z0N2SxgPv4',
          order_id: '3',
          section_id: '2',
          course_id: '1',
        },
      ],
    },
    {
        section_id: '3',
        title: 'JavaScript Basics',
        order_id: '3',
        course_id: '1',
        lessons: [
          {
            lesson_id: '5',
            title: 'Introduction to JavaScript',
            description: 'Introduction to JavaScript and its uses',
            video_url: 'https://www.youtube.com/watch?v=PkZNo7MFNFg',
            order_id: '1',
            section_id: '3',
            course_id: '1',
          },
          {
            lesson_id: '6',
            title: 'JavaScript Variables',
            description: 'Introduction to JavaScript variables',
            video_url: 'https://www.youtube.com/watch?v=ukZFI9rgwfU',
            order_id: '2',
            section_id: '3',
            course_id: '1',
          },
        ],
      },
      {
        section_id: '4',
        title: 'React Basics',
        order_id: '4',
        course_id: '1',
        lessons: [
          {
            lesson_id: '7',
            title: 'Introduction to React',
            description: 'Introduction to React and its uses',
            video_url: 'https://www.youtube.com/watch?v=Ke90Tje7VS0',
            order_id: '1',
            section_id: '4',
            course_id: '1',
          },
          {
            lesson_id: '8',
            title: 'React Components',
            description: 'Introduction to React components',
            video_url: 'https://www.youtube.com/watch?v=8N_eUiLqfnY',
            order_id: '2',
            section_id: '4',
            course_id: '1',
          },
        ],
      },
      {
        section_id: '5',
        title: 'Introduction to React',
        order_id: '5',
        course_id: '1',
        lessons: [
          {
            lesson_id: '9',
            title: 'What is React?',
            description: 'Introduction to React and its uses',
            video_url: 'https://www.youtube.com/watch?v=Ke90Tje7VS0',
            order_id: '1',
            section_id: '5',
            course_id: '1',
          },
          {
            lesson_id: '10',
            title: 'React Components',
            description: 'Introduction to React components',
            video_url: 'https://www.youtube.com/watch?v=8N_eUiLqfnY',
            order_id: '2',
            section_id: '5',
            course_id: '1',
          },
        ],
      },
      {
        section_id: '6',
        title: 'Introduction to Node.js',
        order_id: '6',
        course_id: '1',
        lessons: [
          {
            lesson_id: '11',
            title: 'What is Node.js?',
            description: 'Introduction to Node.js and its uses',
            video_url: 'https://www.youtube.com/watch?v=uVwtVBpw7RQ',
            order_id: '1',
            section_id: '6',
            course_id: '1',
          },
          {
            lesson_id: '12',
            title: 'Node.js Modules',
            description: 'Introduction to Node.js modules',
            video_url: 'https://www.youtube.com/watch?v=O8wwnhdkPE4',
            order_id: '2',
            section_id: '6',
            course_id: '1',
          },
        ],
      },
      {
        section_id: '7',
        title: 'Database Fundamentals',
        order_id: '7',
        course_id: '1',
        lessons: [
          {
            lesson_id: '13',
            title: 'Introduction to Databases',
            description: 'Introduction to databases and their uses',
            video_url: 'https://www.youtube.com/watch?v=FR4QIeZaPeM',
            order_id: '1',
            section_id: '7',
            course_id: '1',
          },
          {
            lesson_id: '14',
            title: 'Relational Databases',
            description: 'Introduction to relational databases',
            video_url: 'https://www.youtube.com/watch?v=_HLwpJpO8mI',
            order_id: '2',
            section_id: '7',
            course_id: '1',
          },
        ],
      },
  ];

  export default mockSections;