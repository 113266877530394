import styled from "styled-components";
const FileInput = styled.input`
  padding: 0.9rem;
  font-size: 1.4rem;
  width: 100%;
  font-family: ${({ theme }) => theme.primaryFont};
  border:1px solid ${({theme}) => theme.palette.common.grey};
  border-radius:5px;
  @media only screen and (min-width: 768px) {
    font-size: 1.5rem;
  }
`;
export default FileInput;
