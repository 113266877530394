import 'bulma/css/bulma.min.css';
import './App.css';
import {useAuthState} from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';
import Authentication from './views/Authentication/Authentication';
import CoursePage from './views/CoursePage/CoursePage';
import "bootstrap/dist/css/bootstrap.min.css";
function App() {
    const [authUser] = useAuthState(getAuth());
  return (

      (authUser == null ? <Authentication/> : <div>
          <nav className="navbar navbar-dark" style={{backgroundColor: "#004B87", fontFamily: "Marck Script", padding: "10px"}}>
              <span className="navbar-brand mb-0 h1" style={{fontSize: "2.8rem"}}>Platform Posse</span>
          </nav> <CoursePage/> </div>)
  );
}

export default App;
