import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

console.log(process.env.REACT_APP_FIREBASE_API_KEY);
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "media-player-838e5.firebaseapp.com",
  projectId: "media-player-838e5",
  storageBucket: "media-player-838e5.appspot.com",
  messagingSenderId: "134539888061",
  appId: process.env.REACT_APP_FIRESTORE_APP_ID,
  measurementId: "G-T76X0QXWKR"
};

// Initialize Firebase
initializeApp(firebaseConfig);
