import React, {useState} from "react";
import styled from "styled-components";
import CourseNavigation from "./CourseNavigation/CourseNavigation";
import mockSections from "./MockSections";
import LessonContent from "./LessonContent/LessonContent";
import { Lesson } from "../../types";


const CoursePage = () => {
    const [selectedChat, setSelectedChat] = useState<Lesson>(mockSections[0].lessons[0]);
    const changeSelectedChat = (lesson : Lesson) => {
        setSelectedChat(lesson);
    }
    return (
        <CoursePageWrapper>

            <CourseNavigation activeLesson ={selectedChat}sections = {mockSections} changeSelectedChat = {changeSelectedChat}/>
            <LessonContent lesson = {selectedChat}></LessonContent>
        </CoursePageWrapper>
    )
}
const CoursePageWrapper = styled.div`
    display:flex;
    flex-flow: row nowrap;
    gap:2rem;
    width:100%;
    overflow-y:auto;
   & > :last-child {
        flex: 1 1 auto;
   } 
`;
export default CoursePage;