import React from "react";
import styled from "styled-components";
import { Lesson as ILesson } from "../../../types";
import { BasicText, FlexContainer, Heading } from "../../../components/utilities";
import ReactPlayer from "react-player";

interface IProps{
    lesson: ILesson
}
const LessonContent = ({lesson} : IProps) => {
    return (
        <LessonContentWrapper>
            <Heading style={{fontFamily : "Proza Libre,sans-serif", fontWeight: "bold"}}>{lesson.title}</Heading>
            <FlexContainer direction="row" justifyContent="center" height="100%">
            <ReactPlayer style={{position:'relative', maxHeight: "1100px", maxWidth: "1100px", borderRadius: "5px"}} url={lesson.video_url} controls={true} pip={true} width="100%" height = "100%"></ReactPlayer>

            </FlexContainer>
            <FlexContainer direction="column" alignItems="start" maxWidth="1100px">
            <BasicText fontSize="1.9rem" fontWeight="bold">Description:</BasicText>
            <BasicText fontSize="1.6rem" fontWeight="400">{lesson.description}</BasicText>
            </FlexContainer>
        </LessonContentWrapper>
    )
}

const LessonContentWrapper = styled.div`
    display:flex;
    flex-flow: column nowrap;
    gap:4rem;
    align-items:center;
    padding:5rem 2.5rem 2.5rem 2.5rem;
    & > h1 {
        text-align:start;
    }
`;

export default LessonContent;