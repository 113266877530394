import React from "react"
import styled from "styled-components"
import { Section as ISection, Lesson as ILesson } from "../../../../types"
import { BasicText, FlexContainer } from "../../../../components/utilities"
import Lesson from "./Lesson"

interface IProps{
    sectionNumber : number,
    section : ISection
    changeSelectedChat: (lesson : ILesson) => void;
    activeLesson : ILesson
}

const Section = ({sectionNumber, section, changeSelectedChat, activeLesson} : IProps) => {
    return (
        // <SectionWrapper>
        //     <FlexContainer direction="row"  padding="1rem">
        //     <BasicText style={{fontFamily: "Proza Libre, sans-serif"}} fontSize="1.8rem" fontWeight="bold">Section {sectionNumber} : {section.title}</BasicText>
        //     </FlexContainer>
        //     <FlexContainer direction="column" gap="0rem" >
        //             {section.lessons.map((lesson : ILesson) => (<Lesson lesson={lesson} key = {lesson.lesson_id} changeSelectedChat={changeSelectedChat}></Lesson>))}
        //     </FlexContainer>
        // </SectionWrapper>
        <SectionWrapper>

        <div className="accordion accordion-flush" id="accordionExample">
            <div className="card">
                <h2 className="card-header"
                    id="headingOne"
                    style={{backgroundColor: "whitesmoke"}}>

                    <BasicText fontSize="1.4rem"
                               fontWeight="bold">{section.title}</BasicText>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                     data-bs-parent="#accordionExample">
                    <div className="card-body">
                        <FlexContainer direction="column" gap="0rem" >
                            <div className="list-group list-group-flush">
                                        {section.lessons.map((lesson : ILesson) => (<li className={`list-group-item ${activeLesson === lesson ? "active" : ""}`}  onClick={() => changeSelectedChat(lesson) } > {lesson.title} </li>))}
                            </div>
                        </FlexContainer>
                    </div>
                </div>
            </div>

        </div>


            </SectionWrapper>
    )
}
const SectionWrapper = styled.div`
    display:flex;
    flex-flow: column nowrap;
    gap:0.8rem;
`;
export default Section;