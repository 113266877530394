import { DefaultTheme } from "styled-components";

const Theme: DefaultTheme = {
  palette: {
    primary: {
      main: "#299963",
      contrastText: "#000000",
    },
    secondary: {
      main: "#D03D56",
      contrastText: "#FFFFFF",
    },
    common: {
      white: "#FFFFFF",
      black: "#000000",
      grey: "#dbdbdb",
      darkGrey: "#8E8E8E",
      lightGrey: "#f4f5f5",
    },
    pale: "#c9cafa",
  },
  primaryFont: `Roboto, sans-serif`,
};

export default Theme;
