import React, { useState } from "react";
import Signup from "./SignUp";
import Login from "./Login";
import styled from "styled-components";

const Authentication = () => {
    const [status, setStatus] = useState(true);
    const changeStatus = () => {
        setStatus(!status);
    }
    return (
        <AuthenticationWrapper>
        {status === true ? <Login onClick = {changeStatus}/> : <Signup onClick = {changeStatus}/> }
        </AuthenticationWrapper>
    )

}
const AuthenticationWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: grid;
  padding:1rem;
  justify-items: center;
  align-content: center;
  gap: 1.3rem;
  background-color: ${({ theme }) => theme.palette.common.white};
  @media only screen and (min-width: 540px) {
  padding:2.5rem;
  }
`;
export default Authentication;