import styled from "styled-components";

const FormContainer = styled.form`
  display: grid;
  align-items: center;
  justify-items: center;
  justify-content:center;
  gap: 1.5rem;
  width: min(100%, 350px);
  background-color: ${({ theme }) => theme.palette.common.white};
  & > h1:first-child{
    margin-bottom:3rem;
  }
  @media only screen and (min-width: 540px) {
    border: 1px solid ${({ theme }) => theme.palette.common.grey};
    width:90%;
    max-width:600px;
    padding: 2rem 2rem;
  }
  
  
`;

export default FormContainer;
